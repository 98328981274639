import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import { ButtonLink } from '../UI';

export const Wrapper = styled.header`
  background: rebeccapurple;
  width: 100%;
  height: 70px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const HomeLink = styled(ButtonLink)`
  padding: 0;
  min-width: 0;
  color: white;
`;

export const NavLink = styled(ButtonLink)`
  color: white;
`;

export const NavButton = styled(Button)`
  color: white;
`;

export const CreditChip = styled(Chip)`
  border: 1px solid white;

  .MuiChip-label {
    color: white;
  }

  .MuiChip-avatar {
    background: white;
  }
`;
