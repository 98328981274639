import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { getPriceLabels } from '../../utils';

const Price = (props) => {
  const { info, label } = getPriceLabels(props);
  return (
    <Tooltip title={info}>
      <span>
        {label}
      </span>
    </Tooltip>
  );
};

export default Price;
