import React, { useState, useCallback, useEffect } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

function GoogleMap({
  showCenterMarker,
  google,
  center,
  initialCenter,
  zoom,
  content,
  markers = [],
  renderInfo = () => null,
}) {
  const [bounds, setBounds] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [selectedPlace, setSelectedPlace] = useState();
  const handleMarkerClick = useCallback(
    (props, marker) => {
      setSelectedPlace(props);
      setActiveMarker(marker);
    },
    [setActiveMarker, setSelectedPlace],
  );

  const renderMarkers = (marks) => marks.map((marker) => (
    <Marker
      onClick={handleMarkerClick}
      {...marker}
    />
  ));

  useEffect(
    () => {
      const bnds = new google.maps.LatLngBounds();
      if (markers.length) markers.forEach((marker) => bnds.extend(marker.position));
      else bnds.extend(initialCenter || center);
      setBounds(bnds);
    },
    // Only re-compute if marker length changes
    [markers.length],
  );

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <Map
        containerStyle={containerStyle}
        google={google}
        initialCenter={initialCenter || center || markers[0].position}
        bounds={bounds}
      >
        {renderMarkers(markers)}
        {showCenterMarker && (
          <Marker
            title={content}
            position={center}
          />
        )}
        <InfoWindow
          marker={activeMarker}
          visible={!!activeMarker}
          onClose={() => setActiveMarker()}
        >
          {selectedPlace ? renderInfo(selectedPlace) : null}
        </InfoWindow>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDZgEB5jC1hMEg72CDRMR3NUB9IoG17D5E',
})(GoogleMap);
