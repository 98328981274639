import React from 'react';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const SelectInputField = ({
  input, options, label, margin = 'dense', ...props
}) => (
  <FormControl margin={margin} fullWidth>
    <InputLabel htmlFor={input.name}>{label}</InputLabel>
    <Select
      native
      value={input.value}
      onChange={input.onChange}
      inputProps={{
        name: input.name,
        id: input.name,
      }}
      variant="standard"
      {...props}
    >
      {options.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </Select>
  </FormControl>
);

export default SelectInputField;
