import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, reduxForm } from 'redux-form';

import { InputField, Form, Avatar } from '../../UI';
import { Submit } from './AuthModal.style';

const validate = ({ password = '', verifyPassword = '' }) => {
  const errors = {};
  if (password.length < 8) errors.password = 'Must be at least 8 characters';
  if (verifyPassword !== password) errors.verifyPassword = 'Passwords don\'t match';
  return errors;
};

/*
  This form tracks a token field that isn't rendered but is received from the query 
  string payload and passed to resetPassword endpoint
*/
const ResetPasswordForm = (props) => {
  const {
    handleSubmit, submitting, actions
  } = props;

  return (
    <>
      <Avatar>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Set a new password
      </Typography>
      <Form onSubmit={handleSubmit}>
        <Field
          name="password"
          type="password"
          component={InputField}
          label="Password"
          fullWidth
        />
        <Field
          name="verifyPassword"
          type="password"
          component={InputField}
          label="Verify Password"
          fullWidth
        />
        <Submit
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Submit
          {submitting && <CircularProgress size={20} color="secondary" />}
        </Submit>
      </Form>
      <Grid container>
        <Grid item>
          <Link component="button" onClick={() => actions.showModal('login')}>
            Remember your original password?
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default reduxForm({
  form: 'signup',
  fields: ['password', 'verifyPassword', 'token'],
  validate,
})(ResetPasswordForm);
