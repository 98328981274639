import 'isomorphic-fetch';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider } from './components/AuthProvider';
import { StripeProvider } from './components/StripeProvider';
import createStore from './store/createStore';
import ApolloProvider from './components/ApolloProvider';

import theme from './theme';
import Routes from './routes';

const GlobalStyle = createGlobalStyle`
  body {
    background: #ddd;
  }
  img {
    max-width: 100%;
  }
`;

export default function App() {
  // Remnant from gatsby could initialise in store.js now
  const store = createStore();
  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ReduxProvider store={store}>
              <AuthProvider>
                <ApolloProvider>
                  <StripeProvider>
                    <Routes />
                  </StripeProvider>
                </ApolloProvider>
              </AuthProvider>
            </ReduxProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
}
