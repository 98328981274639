import React from 'react';

import Alert from '@material-ui/lab/Alert';

import { Paper } from './AuthModal.style';
import Modal from '../../Modal';

import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import VerifyForm from './VerifyForm';
import ContinueSignupForm from './ContinueSignupForm';
import RequestPasswordResetForm from './RequestPasswordResetForm';
import PasswordResetForm from './PasswordResetForm';

const AuthModal = ({ visible, onClose, mode, name, error, actions, initialValues }) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <Paper>
        {mode === 'login' && <LoginForm initialValues={initialValues} onSubmit={actions.login} actions={actions} />}
        {mode === 'signup' && <SignupForm initialValues={initialValues} onSubmit={actions.signup} actions={actions} />}
        {mode === 'verify' && <VerifyForm initialValues={initialValues} onSubmit={actions.verify} actions={actions} />}
        {mode === 'continue' && <ContinueSignupForm initialValues={initialValues} onSubmit={actions.signup} actions={actions} />}
        {mode === 'forgot' && <RequestPasswordResetForm initialValues={initialValues} onSubmit={actions.requestPasswordReset} actions={actions} />}
        {mode === 'passwordReset' && <PasswordResetForm initialValues={initialValues} onSubmit={actions.passwordReset} actions={actions} />}
        {mode === 'logout' && (
          <>
            {`Logged in as ${name}`}
            <button type="submit" onClick={actions.logout}>LOGOUT</button>
          </>
        )}
        {error && <Alert severity="error">{error}</Alert>}
      </Paper>
    </Modal>
  );
};

export default AuthModal;
