import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { Paper } from '../UI';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) return (
      <Container maxWidth="sm">
        <Paper>
          <Typography variant="h5">Uh oh, something went wrong</Typography>
          <br />
          <Typography variant="p" paragraph>Refresh the page and try again.</Typography>
          <Typography variant="p" paragraph>
            If the problem persists
            {' '}
            <s>just keep trying</s>
            {' '}
            try the
            {' '}
            <Link href="/">homepage</Link>
          </Typography>
          <Typography variant="p" paragraph>
            If you're still seeing this then you'll need to shut your computer down and burn it.
          </Typography>
          <Typography variant="p" paragraph>
            But seriously if you would like to provide some more detail by clicking the button below that would be great.
            We have already logged the error but it&apos;s always nice to put a face to a bug..
          </Typography>
          <br />
          <Button
            color="primary"
            variant="contained"
            onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
          >
            Report feedback
          </Button>
        </Paper>
      </Container>
    );


    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
