import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import env from '../../env.json';

const PUBLIC_STRIPE_KEY = env.CONTEXT === 'production'
  ? process.env.REACT_APP_SNIPCART_PUBLIC_KEY : process.env.REACT_APP_SNIPCART_PUBLIC_KEY_DEV;

const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

export function StripeProvider({ children }) {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
}

// export default StripeContext;
export default StripeProvider;
