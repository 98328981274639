/**
 * Layout component that wraps most routes with Header and width wrapper
 */

import React from 'react';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Header siteTitle=" Scriptic" />
      <Container disableGutters={!isMedium}>
        <main>{children}</main>
        <Footer />
      </Container>
    </>
  );
}

export default Layout;
