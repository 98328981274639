import React from 'react';
import {
  Switch,
  Route,
  useParams,
} from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Paper } from '../../components/UI';
import Layout from '../../components/layout';
import PrivateRoute from '../../components/PrivateRoute';

import HostScriptModal from './host';
import PlayScriptModal from './play';
import BuyModal from './buy';
import EditScript from './edit';
import Detail from './detail';

const GET_SCRIPT = gql`
  query script($scriptId: ID!) {
    findScriptByID(id: $scriptId) {
      _id
      title
      description
      private
      welcome
      goodbye
      price {
        value
        fixed
      }
      author {
        _id
        name
      }
      steps {
        clue
        answers
        hints
      }
      games {
        data {
          status
        }
      }
      startLocation {
        description
        googlePlaceId
        coordinates {
          lat
          lng
        }
      }
      tags
      images {
        data {
          _id
          publicId
        }
      }
      primaryImage {
        _id
        publicId
      }
      stats {
        rating
      }
      type
    }
  }
`;

export default function ScriptRouter() {
  const { scriptId } = useParams();
  const basepath = `/scripts/${scriptId}`;
  const { error, data, refetch } = useQuery(GET_SCRIPT, { variables: { scriptId } });

  if (!data) return <Layout><CircularProgress /></Layout>;

  if (error) return (
    <Layout>
      <Paper>
        Failed to fetch script data
        <p>{error.message}</p>
      </Paper>
    </Layout>
  );

  if (!data.findScriptByID) return (
    <Layout>
      <Paper>
        We could not find the script for that ID.
        It may have been deleted by the author or made private.
      </Paper>
    </Layout>
  );

  return (
    <Switch>
      <PrivateRoute path={`${basepath}/edit`}>
        <EditScript script={data.findScriptByID} refetch={refetch} />
      </PrivateRoute>
      <Route path={basepath}>
        <Detail script={data.findScriptByID} refetch={refetch} />
        <PrivateRoute noFallback path={`${basepath}/play`}>
          <PlayScriptModal script={data.findScriptByID} />
        </PrivateRoute>
        <PrivateRoute noFallback path={`${basepath}/buy`}>
          <BuyModal script={data.findScriptByID} />
        </PrivateRoute>
        <PrivateRoute noFallback path={`${basepath}/host`}>
          <HostScriptModal script={data.findScriptByID} />
        </PrivateRoute>
      </Route>
    </Switch>
  );
}
