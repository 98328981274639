import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Paper } from '../../components/UI';
import Layout from '../../components/layout';

import Detail from './detail';

const GET_USER_ID = gql`
  query getUserId($name: String!) {
    userByName(name: $name) {
      _id
    }
  }
`;

const GET_USER = gql`
  query user($userId: ID!) {
    findUserByID(id: $userId) {
      name
      scripts {
        data {
          _id
          title
          description
          price {
            value
            fixed
          }
          startLocation {
            description
            googlePlaceId
            coordinates {
              lat
              lng
            }
          }
          tags
          primaryImage {
            _id
            publicId
          }
          images {
            data {
              _id
              publicId
            }
          }
          stats {
            rating
            reviews
            started
            completed
          }
          type
        }
      }
    }
  }
`;

export default function User() {
  const { userName } = useParams();
  const [getUser, { error, data, refetch }] = useLazyQuery(GET_USER);
  const getUserId = useQuery(
    GET_USER_ID,
    {
      variables: { name: userName },
      onCompleted: (d) => {
        if (d.userByName) getUser({ variables: { userId: d.userByName._id } });
      },
    },
  );
  if (!getUserId.data || !data) return <Layout><CircularProgress /></Layout>;

  if (error || getUserId.error) return (
    <Layout>
      <Paper>
        Failed to fetch user data
        <p>{error.message}</p>
      </Paper>
    </Layout>
  );

  if (!getUserId.data || !getUserId.data.userByName) return (
    <Layout>
      <Paper>
        We could not find a user by that name
      </Paper>
    </Layout>
  );

  return (
    <Detail user={data.findUserByID} refetch={refetch} />
  );
}
