import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, reduxForm } from 'redux-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

import { Form, Avatar } from '../../UI';
import { PhoneNumberInput } from '../../UI/form';
import { Submit } from './AuthModal.style';

const validate = ({ phoneNumber = '' }) => {
  const errors = {};
  // TODO: Handle other countries phone numbers
  const phone = parsePhoneNumberFromString(phoneNumber, 'GB');
  if (!phone || !phone.isValid()) errors.phoneNumber = 'Not a valid mobile number';
  return errors;
};

const SignupForm = ({ handleSubmit, submitting, actions }) => (
  <>
    <Avatar>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Forgotten Password
    </Typography>
    <Form onSubmit={handleSubmit}>
      <Field
        name="phoneNumber"
        placeholder="+447888100100"
        type="text"
        component={PhoneNumberInput}
        label="Phone Number"
        autoComplete="phone"
        autoFocus
        fullWidth
        id="phoneNumber"
      />
      <Submit
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting}
      >
        Reset Password
        {submitting && <CircularProgress size={20} color="secondary" />}
      </Submit>
    </Form>
    <Grid container>
      <Grid item>
        <Link component="button" onClick={() => actions.showModal('login')}>
          {"Back to Log In form"}
        </Link>
      </Grid>
    </Grid>
  </>
);

export default reduxForm({
  form: 'signup',
  validate,
})(SignupForm);
