import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import env from './env.json';

import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: env.CONTEXT ? 'https://d1e1cb53464f4b248d4f11ed1a22a8b4@o405603.ingest.sentry.io/5271569' : null,
  environment: env.CONTEXT,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  window.document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
