import styled from 'styled-components';

import MuiCardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

export const CardMedia = styled(MuiCardMedia)`
  height: 140px;
`;

export const Description = styled(Typography)`
  max-lines: 6;
`;

export const MasonryGrid = styled.div`
  columns: 3 200px;
  column-gap: 1rem;

  > div {
    width: 100%;
    margin: 0 1rem 1rem 0;
    display: inline-block;
  }
`;
