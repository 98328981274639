import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useFetch } from '../../hooks';

import { Wrapper, Message, TextField } from './GameEvents.style';

export default function GameEvents({ events = [], showSender, refetch, gameId }) {
  const [sendMessage, sendingMessage, response] = useFetch({ url: `/.netlify/functions/games/${gameId}/message` });

  const [message, setMessage] = useState('');

  const handleSendMessage = useCallback(
    () => sendMessage({ data: { gameId, message } }),
    [sendMessage, gameId, message],
  );

  useEffect(
    () => {
      const id = get(response, ['data', 'account']);
      if (id) {
        refetch();
        setMessage('');
      }
    },
    [response],
  );

  return (
    <Wrapper>
      {events.map(({ eventType, payload }) => (
        <Grid container justify={eventType === 'SMS_RECEIVED' ? 'flex-end' : 'flex-start'}>
          <Message type={eventType}>
            <Typography variant="p">{payload}</Typography>
          </Message>
        </Grid>
      ))}
      {showSender && (
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          variant="outlined"
          fullWidth
          size="small"
          placeholder="1 Credit"
          inputProps={{
            maxLength: 160,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={handleSendMessage} color="inherit" aria-label="menu">
                {sendingMessage ? <CircularProgress size={20} /> : <SendIcon color="primary" fontSize="small" />}
              </IconButton>
            ),
          }}
        />
      )}
    </Wrapper>
  );
}
