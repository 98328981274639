import React, { useCallback, useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import get from 'lodash/get';
import Link from '@material-ui/core/Link';

import { getErrorMessage, deformifyScript, formifyScript } from '../../utils';
import { useAuthContext } from '../../hooks';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ScriptForm from '../../components/ScriptForm';
import { Paper } from '../../components/UI';

const CREATE_SCRIPT = gql`
  mutation CreateScript (
    $title: String!
    $description: String
    $steps: [StepInput!]!
    $author: ID
    $private: Boolean!
    $price: PriceInput!
    $welcome: String
    $goodbye: String
    $startLocation: LocationInput
    $type: ScriptType!
    $tags: [String!]
  ) {
    createScript(data: {
      title: $title
      description: $description
      welcome: $welcome
      goodbye: $goodbye
      price: $price
      steps: $steps
      author: { connect: $author }
      private: $private
      deleted: false
      startLocation: $startLocation
      type: $type
      tags: $tags
      stats: {
        started: 0
        completed: 0
        reviews: 0
      }
    }) {
      _id
    }
  }
`;

const initialValues = {
  steps: [{
    clue: '',
    id: uniqueId(),
  }],
  type: 'TRAIL',
  price: {
    value: 0,
  },
};

const CreateScript = () => {
  const [createScript, { data, error }] = useMutation(CREATE_SCRIPT);
  const { userId, actions } = useAuthContext();
  const history = useHistory();
  const [snack, setSnack] = useState(null);

  const handleSubmit = useCallback(
    async (formValues) => {
      if (!userId) return setSnack('NOT_LOGGED_IN');
      const script = await deformifyScript(formValues);
      return createScript({
        variables: {
          ...script,
          author: userId,
        },
      });
    },
    [createScript, userId],
  );

  useEffect(
    () => {
      const id = get(data, ['createScript', '_id']);
      if (id) history.push(`/scripts/${data.createScript._id}`);
    },
    [history, data],
  );

  useEffect(
    () => {
      if (error) return setSnack(getErrorMessage(error));
      return setSnack(null);
    },
    [error, setSnack],
  );

  const showLoginModal = useCallback(
    () => {
      actions.showModal('login');
      setSnack(null);
    },
    [],
  );

  return (
    <Layout>
      <SEO title="New Script" />
      <Paper>
        <Typography variant="h4">New Script</Typography>
        <Typography variant="p" paragraph>
          Enter as many steps as you like each with a single question and as many answers and hints as you like. If you don&apos;t enter an answer it will be treated as an open question and whatever the person responds with will be accepted.
        </Typography>
        <Typography variant="p" paragraph>
          {'The answers given are saved and can be used in your question e.g. Hello {ANSWER_1}, how\'s it going?.'}
        </Typography>
        <Typography variant="p" paragraph>
          {'You can also use * in your accepted answers and it will match any characters so *eggs* will match the players response of \'scrambled eggs on toast\''}
        </Typography>
        <ScriptForm onSubmit={handleSubmit} initialValues={formifyScript(initialValues)} />
        <Snackbar open={!!snack} autoHideDuration={6000} onClose={() => setSnack(null)}>
          <Alert onClose={() => setSnack(null)} severity="error">
            {snack === 'NOT_LOGGED_IN'
              ? <span>You need to <Link component="button" onClick={showLoginModal}>log in</Link> before you can save this script</span>
              : snack}
          </Alert>
        </Snackbar>
      </Paper>
    </Layout>
  );
};
// TODO: Sort out error response shape madness
export default CreateScript;
