import React, { useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Image } from 'cloudinary-react';

import MuiGridListTile from '@material-ui/core/GridListTile';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import BinIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import env from '../../../env.json';
import {
  ButtonDialog,
  GridList,
  GridListTileBar,
  StarIconButton,
  BinIconButton,
} from '../../../components/UI';
import { useFetch } from '../../../hooks';

const UPLOAD_IMAGES = gql`
  mutation EditScript (
    $scriptId: ID!
    $images: [ScriptImageInput!]!
  ) {
    partialUpdateScript(
      id: $scriptId
      data: {
        images: {
          create: $images
        }
      }) {
      _id
    }
  }
`;

const SELECT_PRIMARY_IMAGE = gql`
  mutation EditScript (
    $scriptId: ID!
    $imageId: ID!
  ) {
    partialUpdateScript(
      id: $scriptId
      data: {
        primaryImage: {
          connect: $imageId
        }
      }) {
      _id
    }
  }
`;

// TODO: Work out a better way of doing this.. Maybe just use FQL in lambda?
// We have to disconect the relationship of the old primaryImage before setting
// a new one
const CHANGE_PRIMARY_IMAGE = gql`
  mutation EditScript (
    $scriptId: ID!
    $imageId: ID!
  ) {
    disc: partialUpdateScript(
      id: $scriptId
      data: {
        primaryImage: {
          disconnect: true
        }
      }) {
      _id
    }
    update: partialUpdateScript(
      id: $scriptId
      data: {
        primaryImage: {
          connect: $imageId
        }
      }) {
      _id
    }
  }
`;

// TODO: Use apollo cache update instead of refetching to save db reads
// TODO: Secure this in faunadb ensure images are only created for authored scripts
// TODO: Monitor cloudinary usage and adjst responsive props if getting hammered
export default function ScriptImages({ scriptId, images, primaryImage, refetch, isAuthor }) {
  const [editScript] = useMutation(UPLOAD_IMAGES);
  const [selectPrimary] = useMutation(SELECT_PRIMARY_IMAGE);
  const [changePrimary] = useMutation(CHANGE_PRIMARY_IMAGE);
  const [widget, setWidget] = useState({});
  const [deleteImage] = useFetch({
    method: 'DELETE',
  });

  const handleSelectPrimary = useCallback(
    async (imageId) => {
      const mutate = primaryImage && primaryImage._id ? changePrimary : selectPrimary;
      await mutate({ variables: { imageId, scriptId } });
      refetch();
    },
    [scriptId, primaryImage],
  );

  const handleUpload = useCallback(
    async (e, result) => {
      if (!e && result && result.event === 'queues-end') {
        await editScript({
          variables: {
            scriptId,
            images: result.info.files.map((file) => ({
              publicId: file.uploadInfo.public_id,
            })),
          },
        });
        refetch();
      }
    },
    [scriptId],
  );

  const handleDeleteImage = useCallback(
    async (imageId) => {
      await deleteImage({
        url: `/.netlify/functions/scripts/${scriptId}/images/${imageId}`,
      });
      refetch();
    },
    [scriptId],
  );

  useEffect(
    () => {
      setWidget(window.cloudinary.createUploadWidget(
        {
          cloudName: 'scriptic',
          uploadPreset: 'scripts',
          context: {
            scriptId,
            environment: env.CONTEXT,
          },
        },
        handleUpload,
      ));
    },
    [],
  );
  if (!images.length && !isAuthor) return null;
  return (
    <GridList>
      {images.map((image) => (
        <MuiGridListTile key={image.publicId}>
          <Image
            cloudName="scriptic"
            publicId={image.publicId}
            dpr="auto"
            height="200"
            crop="scale"
            responsive
            fetchFormat="auto"
          />
          {isAuthor && (
            <GridListTileBar>
              <StarIconButton onClick={() => handleSelectPrimary(image._id)}>
                {primaryImage && (primaryImage._id === image._id)
                  ? <StarIcon />
                  : <StarBorderIcon />}
              </StarIconButton>
              <ButtonDialog
                title="Delete Image"
                content="Are you sure you want to delete this image?"
                label="Yes"
                action={() => handleDeleteImage(image._id)}
              >
                {({ handleClick }) => (
                  <BinIconButton onClick={handleClick}>
                    <BinIcon />
                  </BinIconButton>
                )}
              </ButtonDialog>
            </GridListTileBar>
          )}
        </MuiGridListTile>
      ))}
      {isAuthor && (
        <MuiGridListTile>
          <Button onClick={widget.open}>Add Images</Button>
        </MuiGridListTile>
      )}
    </GridList>
  );
}
