import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import DragIcon from '@material-ui/icons/DragIndicator';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import { ItemTypes } from './ItemTypes';
import { StepWrapper, Grip, Actions } from './ScriptForm.style';
import AlertDialog from '../AlertDialog';


const Step = ({ id, children, index, moveStep, deleteStep, canDelete }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.STEP,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Time to actually perform the action
      moveStep(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.STEP, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  const [deleteDialogIsOpen, setDeleteDialogOpen] = React.useState(false);

  return (
    <StepWrapper ref={ref} isDragging={isDragging}>
      <Actions>
        <Grip ref={drag}><DragIcon /></Grip>
        {canDelete && (
          <IconButton
            aria-label="delete"
            title="Remove Step"
            onClick={() => setDeleteDialogOpen(true)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Actions>
      <AlertDialog
        title="Delete step"
        content="Are you sure you want to delete this step from your script?"
        open={deleteDialogIsOpen}
        handleClose={() => setDeleteDialogOpen(false)}
      >
        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
          No
        </Button>
        <Button onClick={deleteStep} color="primary" autoFocus>
          Yes
        </Button>
      </AlertDialog>
      {children}
    </StepWrapper>
  );
};

export default Step;
