import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { useStripe } from '@stripe/react-stripe-js';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { useAuthContext, useFetch } from '../../hooks';

import SuccessModal from './success';

const IndexPage = () => {
  const { loggedIn, jwt } = useAuthContext();
  const stripe = useStripe();
  const [getCheckoutSession] = useFetch({
    url: '/.netlify/functions/stripe/session/create',
    throwOnError: true,
  });

  const handleClick = useCallback(
    async () => {
      // When the customer clicks on the button, redirect them to Checkout.
      try {
        const { data: { sessionId } } = await getCheckoutSession();
        await stripe.redirectToCheckout({
          sessionId,
        });
      }
      catch (e) {
        Sentry.captureException(e);
      }
    },
    [stripe, jwt],
  );

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Top Up Credits</h1>
      <p>Choose a credit bundle below:</p>
      <div>
        <Button
          disabled={!loggedIn}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >
          100 Credits / £10
        </Button>
      </div>
      <Route path="/topup/success">
        <SuccessModal />
      </Route>
    </Layout>
  );
};

export default IndexPage;
