/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useHistory } from 'react-router-dom';
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { useAuthContext, useFetch } from '../../hooks';

const StartScript = ({ script }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const [valid, setValid] = useState(false);

  const history = useHistory();
  const { phoneNumber: playerNumber, name: playerName, jwt, loggedIn } = useAuthContext();

  const [getPaymentIntent] = useFetch({
    url: '/.netlify/functions/stripe/payment/intent',
    throwOnError: true,
  });

  const scriptId = script._id;

  const closeModal = useCallback(
    () => history.replace(`/scripts/${scriptId}`),
    [history, scriptId],
  );

  const navToGames = useCallback(
    () => history.replace('/play'),
    [history],
  );

  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    setValid(event.complete && !event.error);
    if (event.error) setError(event.error.message);
    else setError(null);
  };

  const handleSubmit = useCallback(
    async () => {
      setPending(true);
      try {
        const intent = await getPaymentIntent({
          data: {
            scriptId,
            playerName,
            playerNumber,
          },
        });

        const result = await stripe.confirmCardPayment(intent.data.client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: playerName,
            },
          },
        });

        if (result.error) setError(result.error.message);
        else if (result.paymentIntent.status === 'succeeded') {
          setSuccess(true);
          setPending(false);
        }
      }
      catch (e) {
        setError(e.message);
        Sentry.captureException(e);
        setPending(false);
      }
    },
    [jwt, stripe, elements],
  );

  const price = script.price.value / 100;
  return (
    <Dialog open onClose={closeModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{`Play ${script.title}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`To play this script on your phone (${playerNumber}), enter your payment details below`}
        </DialogContentText>
        <DialogContentText>
          {script.price.fixed
            ? `This is a fixed price script so ${script.author.name} covers the cost of messages sent out of the price paid which means £${price} is all you need to pay`
            : 'The fee paid is for the script content so you will also need to ensure you have sufficient credits available to actually send and receive the messages'}
        </DialogContentText>
        {!success
          ? (
            <Box mt={2} mb={2}>
              <CardElement
                id="card-element"
                onChange={handleChange}
              />
            </Box>
          )
          : (
            <Alert severity="success">
              Payment successful, your game will start as soon as payment is confirmed on our system
            </Alert>
          )}
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={pending || !loggedIn || error || !valid}
          onClick={success ? navToGames : handleSubmit}
          variant="contained"
          color="primary"
        >
          {pending
            ? <CircularProgress size={20} />
            : success
              ? 'VIEW GAMES'
              : `Pay £${price}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartScript;
