import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

const PhoneNumberInput = ({ input, meta, ...props }) => (
  <MuiPhoneNumber
    {...input}
    defaultCountry="gb"
    onlyCountries={['gb', 'es']}
    localization={{ Spain: 'España' }}
    error={meta.touched && !!meta.error}
    helperText={meta.touched && meta.error}
    {...props}
  />
);

export default PhoneNumberInput;
