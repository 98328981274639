import React, { useMemo } from 'react';
import { ApolloProvider as Provider } from '@apollo/react-hooks';

import makeApolloClient from '../../apollo';
import { useAuthContext } from '../../hooks';

export default function ApolloProvider({ children }) {
  const { actions } = useAuthContext();
  // We need a global reference to the client here so auth provider can
  // clear the store on logout whilst being higher in react tree
  window.apolloClient = makeApolloClient({ refreshJWT: actions.refreshJWT });
  return useMemo(() => (
    <Provider client={window.apolloClient}>
      {children}
    </Provider>
  ), [actions.refreshJWT]);
}
