import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import formatDistance from 'date-fns/formatDistance';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PlayIcon from '@material-ui/icons/PlayArrow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import TextField from '@material-ui/core/TextField';

import Layout from '../../components/layout';
import { Paper, Link } from '../../components/UI';
import { useAuthContext } from '../../hooks';
import SEO from '../../components/seo';


const GET_USER_GAMES = gql`
  query GamesByUser {
    hostedGames {
      data {
        _id
        createdAt
        status
        script {
          _id
          title
        }
        player {
          _id
          name
        }
      }
    }
  }
`;

const HostedGames = () => {
  const { phoneNumber, userId } = useAuthContext();
  const [getGames, {
    loading, error, data: response,
  }] = useLazyQuery(GET_USER_GAMES);

  useEffect(
    () => {
      if (phoneNumber) getGames();
    },
    [phoneNumber, getGames],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: '_id',
      },
      {
        Header: 'Script',
        accessor: (row) => get(row, ['script', 'title'], 0),
        id: 'script',
        Cell: ({ value, row }) => (
          <Link to={`/host/${row.values._id}`}>
            {value}
            {row.values.status === 'ACTIVE' && <PlayIcon fontSize="inherit" />}
          </Link>
        ),
      },
      {
        Header: 'Player',
        accessor: (row) => (userId === row.player._id ? 'Me' : row.player.name),
        id: 'player',
      },
      {
        Header: 'Started',
        accessor: 'createdAt',
        Cell: ({ value }) => `${formatDistance(new Date(value), Date.now())} ago`,
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [userId],
  );

  const data = useMemo(
    () => get(response, ['hostedGames', 'data'], []),
    [response],
  );

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        '_id',
      ],
      sortBy: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    }),
    [],
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    initialState,
  }, useGlobalFilter, useSortBy);

  return (
    <Layout>
      <SEO title="Plays | Scriptic" />
      <Paper>
        <Typography variant="h4">Hosted Games</Typography>
        <p>Here we should see a list of all current and previous games hosted by you. This will include games played by you</p>
        <TextField type="search" onChange={(e) => setGlobalFilter(e.target.value)} label="Search" />
        <div>
          {loading && <CircularProgress size={20} />}
          {!!data.length && (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? (column.isSortedDesc ? ' 🔽' : ' 🔼')
                              : ''}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {error && <div>{error.message}</div>}
        </div>
      </Paper>
    </Layout>
  );
};

export default HostedGames;
