import ApolloClient, { Observable } from 'apollo-boost';
import parseJWT from 'jwt-decode';

import env from '../env.json';

import config from '../config';

const PUBLIC_CLIENT_TOKEN = env.CONTEXT === 'production'
  ? process.env.REACT_APP_CLIENT_TOKEN : process.env.REACT_APP_CLIENT_TOKEN_DEV;

// https://github.com/apollographql/apollo-link/issues/646
const promiseToObservable = (promise) => new Observable((subscriber) => {
  promise.then(
    (value) => {
      if (subscriber.closed) return;
      subscriber.next(value);
      subscriber.complete();
    },
    (err) => subscriber.error(err),
  );
});

const createClient = ({ refreshJWT }) => new ApolloClient({
  uri: `${process.env.REACT_APP_FAUNA_URL}/graphql`,
  request: (operation) => {
    const jwt = localStorage.getItem(config.auth.storageKey);
    const { token } = jwt ? parseJWT(jwt) : {};
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : `Basic ${Buffer.from(`${PUBLIC_CLIENT_TOKEN}:`).toString('base64')}`,
        'X-Schema-Preview': 'partial-update-mutation',
      },
    });
  },
  onError({ graphQLErrors, forward, operation }) {
    // We refresh the jwt if fauna returns unauthorised
    if (graphQLErrors
      && graphQLErrors.some
      && graphQLErrors.some((e) => e.message === 'Invalid database secret.')) return promiseToObservable(refreshJWT())
      .flatMap(() => forward(operation));
    return null;
  },
});

export default createClient;
