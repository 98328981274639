import React from 'react';
import Dialog from '@material-ui/core/Dialog';

export default function CustomizedDialogs({ children, title, onClose, visible }) {
  return (
    <Dialog maxWidth="xs" onClose={onClose} aria-labelledby="customized-dialog-title" open={visible}>
      {children}
    </Dialog>
  );
}
