const validate = (values) => {
  const errors = {};
  if (!values.title) errors.title = 'You must set a script title';

  // Following check shouldn't be possible but there is a bug where the add script form loads
  // with no steps after editing a script
  if (!values.steps || !values.steps.length) errors.title = 'You must add at least one step below';

  if (values.steps) errors.steps = values.steps.map((step) => ({
    clue: step.clue ? '' : 'You need to add a question or delete the step',
    answers: step.answers
      ? step.answers.map(
        (answer) => (answer
          ? ''
          : 'You must enter an answer or remove it by pressing the cross'
        ),
      )
      : null,
    hints: step.hints
      ? step.hints.map(
        (hint) => (hint
          ? ''
          : 'You must enter a hint or remove it by pressing the cross'
        ),
      )
      : null,
  }));
  return errors;
};

export default validate;
