/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import get from 'lodash/get';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { getLocation } from '../../utils';
import { Paper } from '../../components/UI';
import { useFetch, useQueryParams } from '../../hooks';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import config from '../../config';

import MapResults from './MapResults';
import ListResults from './ListResults';
import ScriptFiltersForm from './SearchFiltersForm';

const Scripts = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useQueryParams();
  const [mapMode, setMapMode] = useState(true);
  const [placeDesc, setPlaceDesc] = useState('');
  const [fetchByLocation, loading, res, error] = useFetch({ method: 'GET' });

  useEffect(
    () => {
      const doit = async () => {
        let loc = {};
        if (params.place) loc = await getLocation({ place_id: params.place });
        setPlaceDesc(loc.description);
        const q = qs.stringify({
          ...params,
          ...loc.coordinates,
        });
        fetchByLocation({
          url: `/.netlify/functions/scripts/trails?${q}`,
        });
      };
      doit();
    },
    [params.place, params.tags, params.radius],
  );

  const handleSubmit = useCallback(
    async ({ startLocation, radius, tags = [] }) => {
      const placeId = get(startLocation, 'place_id');
      const query = {
        place: placeId || '',
        radius: placeId ? radius * 1000 : '',
        tags: tags.length ? tags.map((tag) => tag.id).join(';') : '',
      };
      const q = qs.stringify(query, { skipEmptyString: true });
      history.push(`${location.pathname}?${q}`);
    },
    [fetchByLocation, params],
  );

  const data = useMemo(
    () => get(res, ['data', 'data'], []),
    [res],
  );

  return (
    <Layout>
      <SEO title="Trails | Scriptic" />
      <Paper>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">Clue Trails</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setMapMode(!mapMode)}>
              {mapMode ? 'VIEW LIST' : 'VIEW MAP'}
            </Button>
          </Grid>
        </Grid>
        <ScriptFiltersForm
          onSubmit={handleSubmit}
          initialValues={{
            radius: params.radius ? params.radius / 1000 : 10,
            startLocation: {
              place_id: params.place,
              description: placeDesc,
            },
            tags: params.tags ? params.tags.split(';').map((tag) => config.script.tagsById[tag]) : [],
          }}
          isGeoSearch={!!params.place}
        />
        {mapMode
          ? (
            <MapResults
              data={data}
              error={error}
              loading={loading}
            />
          )
          : (
            <ListResults
              data={data}
              error={error}
              loading={loading}
              isGeoSearch={!!params.place}
            />
          )}
      </Paper>
    </Layout>
  );
};

export default Scripts;
