import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Layout from '../layout';
import { Paper } from '../UI';
import { useAuthContext } from '../../hooks';

export default function PrivateRoutePreview() {
  const { actions } = useAuthContext();
  useEffect(
    () => {
      actions.showModal('login');
      return () => actions.showModal(null);
    },
    [],
  );
  return (
    <Layout>
      <Container maxWidth="sm">
        <Paper>
          <Typography variant="h5">You must be logged in to see this page</Typography>
        </Paper>
      </Container>
    </Layout>
  );
}
