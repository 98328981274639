import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import config from '../../../config';

export default function TagInput({ input }) {
  return (
    <Autocomplete
      multiple
      id={input.name}
      options={config.script.tags}
      getOptionLabel={(tag) => tag.label}
      value={input.value || []}
      onChange={(event, newValue) => {
        input.onChange(newValue);
      }}
      groupBy={(option) => option.category}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Tags"
          placeholder="Pick all that apply"
        />
      )}
    />
  );
}
