import React from 'react';
import get from 'lodash/get';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { ButtonLink } from '../../components/UI';
import { useImage } from '../../hooks';
import { CardMedia } from './user.style';

export default function ScriptCard({ script }) {
  const { title, description, _id, primaryImage } = script;
  const { url } = useImage(get(primaryImage, 'publicId', 'scripts/fqxbtrzxhq6u9zdbfcrn'), { height: 200 });
  return (
    <Card>
      {primaryImage && url && (
        <CardMedia
          image={url}
          title="Contemplative Reptile"
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description && description.length > 300 ? `${description.slice(0, 300)}...` : description}
        </Typography>
      </CardContent>
      <CardActions>
        <ButtonLink to={`/scripts/${_id}/play`} size="small" color="primary">
          Play
        </ButtonLink>
        <ButtonLink to={`/scripts/${_id}`} size="small" color="primary">
          Details
        </ButtonLink>
      </CardActions>
    </Card>
  );
}
