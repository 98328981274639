import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Field, reduxForm } from 'redux-form';

import { InputField } from '../../UI';

const VerifyForm = (props) => {
  const {
    handleSubmit, submitting, actions
  } = props;
  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <p>
          Enter the code we just sent to your phone
        </p>
        <Field
          name="code"
          placeholder="123456"
          type="text"
          component={InputField}
          label="Verification Code"
          fullWidth
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Verify
        </Button>
        <Grid container>
          <Grid item>
            <Link component="button" onClick={() => actions.showModal('login')}>
              {"Not received yet? Resend code"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'verify', // a unique identifier for this form
})(VerifyForm);
