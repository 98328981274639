import styled from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';

// TODO: Make a separate component for non message events like HOST_ACTION
export const Message = styled.div`
  padding: 4px 10px;
  border-radius: 14px;
  white-space: pre-wrap;
  background-color: ${({ type, theme }) => (type === 'SMS_RECEIVED' ? theme.palette.secondary.main : theme.palette.primary.main)};
  max-width: 250px;
  color: white;
  ${({ type, theme }) => (type === 'SMS_RECEIVED' ? `margin-left: ${theme.spacing(2)}px` : `margin-right: ${theme.spacing(2)}px`)};
  margin-bottom: 4px;
  ${({ type }) => (type === 'HOST_ACTION' ? `
  background-color: transparent;
  border: none;
  color: inherit;
  margin: 0 0 4px 0;
  max-width: none;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ` : '')}
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(2, 2, 1, 2)};
`;

export const TextField = styled(MuiTextField)`
  background: white;
  border-radius: 14px;
  margin: 4px 0;

  .MuiOutlinedInput-root {
    border-radius: 14px;
    font-size: 0.875rem;
    line-height: 1.43;
    padding: 4px 10px;
  }
`;
