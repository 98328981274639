import styled from 'styled-components';
import Button from '@material-ui/core/Button';


export const Paper = styled.div`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const Submit = styled(Button)`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};
`;