import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import PhoneNumberInput from 'material-ui-phone-number';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

import { getErrorMessage, validatePhoneNumber, formatMobileNumber } from '../../utils';
import { useFetch } from '../../hooks';

const StartScript = ({ script }) => {
  const history = useHistory();
  const [playerNumber, setPlayerNumber] = useState('');
  const [playerName, setPlayerName] = useState('');
  const [blurred, setBlurred] = useState(false);

  const [startScript, startingScript, response] = useFetch({ url: '/.netlify/functions/games/start' });

  const scriptId = script._id;

  const handleStartScript = useCallback(
    () => startScript({
      data: {
        scriptId,
        playerNumber: formatMobileNumber(playerNumber),
        playerName,
      },
    }),
    [scriptId, playerNumber, playerName, startScript],
  );

  const isValid = useMemo(
    () => validatePhoneNumber(playerNumber),
    [playerNumber],
  );

  const closeModal = useCallback(
    () => history.replace(`/scripts/${scriptId}`),
    [history, scriptId],
  );

  useEffect(
    () => {
      const id = get(response, ['data', 'id']);
      if (id) history.push(`/host/${id}`);
    },
    [history, response],
  );

  return (
    <Dialog open onClose={closeModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{`Host ${script.title}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To share the joy of this script with another person, enter their details below and hit START.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Player Name"
          type="phone"
          fullWidth
          placeHolder="Player Name"
          onChange={(e) => setPlayerName(e.target.value)}
          value={playerName}
          required
        />
        <PhoneNumberInput
          label="Phone Number"
          value={playerNumber}
          onChange={setPlayerNumber}
          defaultCountry="gb"
          onlyCountries={['gb', 'es']}
          localization={{ Spain: 'España' }}
          error={blurred && !isValid}
          helperText={blurred && !isValid ? 'Not a valid mobile number' : ''}
          fullWidth
          inputProps={{
            onBlur: () => setBlurred(true),
          }}
        />
        {response && response.error && (
          <Alert severity="error">{getErrorMessage(response.error)}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" disabled={!isValid || !playerName || startingScript} onClick={handleStartScript}>
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartScript;
