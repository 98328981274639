import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { LocationSearchInput, SelectInput, TagInput } from '../../components/UI/form';
import { Form, InputField } from '../../components/UI';

const ScriptFiltersForm = ({ handleSubmit, pristine, reset, submitting, startLocation }) => (
  <Form onSubmit={handleSubmit} noValidate>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Field
          name="tags"
          fullWidth
          component={TagInput}
          label="Tags"
          margin="dense"
          multiline
        />
      </Grid>
      <Grid item xs={get(startLocation, 'place_id') ? 8 : 12}>
        <Field
          name="startLocation"
          label="Start Location"
          component={LocationSearchInput}
        />
      </Grid>
      {!!get(startLocation, 'place_id') && (
        <Grid item xs={4}>
          <Field
            name="radius"
            label="Within"
            component={InputField}
            type="number"
            fullWidth
            InputProps={{
              endAdornment: (
                <span>
                  km
                </span>
              ),
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={6}>
          {!pristine && (
            <Button
              type="submit"
              color="primary"
              disabled={submitting || pristine}
              variant="contained"
            >
              UPDATE
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Form>
);

const ReduxedForm = reduxForm({
  form: 'scriptFilters',
  enableReinitialize: true,
})(ScriptFiltersForm);

export default connect(
  (state) => ({
    startLocation: formValueSelector('scriptFilters')(state, 'startLocation'),
  }),
)(ReduxedForm);
