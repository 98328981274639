import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import axios from 'axios';
import { S2 } from 's2-geometry';

import config from '../config';

// Google maps api fails if we use the global axios as it sends headers
const instance = axios.create();
instance.defaults.headers.common = {};
instance.defaults.headers.common.accept = 'application/json';

// TODO: Make it nice
export const getErrorMessage = (error, defaultMessage = 'An unknown error occurred') => {
  if (typeof error === 'string') return error;
  if (Array.isArray(error)) return getErrorMessage(error[0]);
  return get(error, ['message', 'response', 'data', 'message'])
    || get(error, ['response', 'data', 'error', 'message'])
    || get(error, ['message', 'response', 'data', 'error'])
    || get(error, ['message', 'message'])
    || get(error, ['message'], defaultMessage);
};

export const formatMobileNumber = (str) => parsePhoneNumberFromString(str, 'GB').number;

export const validatePhoneNumber = (phoneNumber) => {
  const phone = parsePhoneNumberFromString(phoneNumber, 'GB');
  if (!phone || !phone.isValid()) return false;
  return true;
};

export const getLocation = async (place) => {
  const { data } = await instance.get(
    `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place.place_id}&key=${process.env.REACT_APP_GOOGLE_KEY}`,
  );
  const coordinates = get(data, ['results', 0, 'geometry', 'location']);
  const formattedAddress = get(data, ['results', 0, 'formatted_address']);
  return {
    coordinates,
    googlePlaceId: place.place_id,
    description: place.description || formattedAddress,
    geohash: S2.latLngToKey(
      coordinates.lat,
      coordinates.lng,
      30, // May not need this level of precision
    ),
  };
};

export const getPlace = async (location) => {
  const latlng = `${location.lat},${location.lng}`;
  const { data } = await instance.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${process.env.REACT_APP_GOOGLE_KEY}`,
  );
  return {
    description: get(data, ['results', 0, 'formattedAddress']),
    place_id: get(data, ['results', 0, 'place_id']),
  };
};


// TODO: Pick up output units from user preferences
export const formatMetres = (metres) => {
  if (metres < 1000) return '< 1km';
  return `${Math.round(metres / 1000)}km`;
};

// Mutate script to work with redux form
export const formifyScript = (script) => {
  const formifiedScript = {
    ...script,
    steps: script.steps.map((step) => ({ ...step, id: uniqueId() })),
    price: script.price.value ? script.price.value / 100 : 0,
    fixed: script.price.fixed,
    tags: script.tags ? script.tags.map((tag) => config.script.tagsById[tag]) : [],
  };
  if (script.startLocation) formifiedScript.startLocation = {
    ...script.startLocation,
    place_id: script.startLocation.googlePlaceId,
  };
  if (script.endLocation) formifiedScript.endLocation = {
    ...script.endLocation,
    place_id: script.endLocation.googlePlaceId,
  };
  return formifiedScript;
};

// Mutate redux form script to work with faunaDB schema
export const deformifyScript = async (script) => {
  let startLocation = null;
  let endLocation = null;
  if (script.startLocation) startLocation = await getLocation(script.startLocation);
  if (script.endLocation) endLocation = await getLocation(script.endLocation);
  return {
    ...script,
    steps: script.steps.map(({ clue, answers, hints }) => ({
      clue,
      answers,
      hints,
    })),
    private: !!script.private, // Prevent pushing null to fauna
    price: {
      currency: 'GBP',
      value: script.price ? script.price * 100 : 0,
      fixed: script.fixed,
    },
    startLocation,
    endLocation,
    tags: script.tags.map((tag) => tag.id),
  };
};

export const getPriceLabels = ({ value, fixed }) => {
  let info;
  let label;
  if (fixed && value) {
    info = 'The script author pays for the messaging costs of this trail';
    label = `£${value / 100}`;
  }
  if (!fixed && value) {
    info = 'You will need to pay for the script plus the messaging credits used';
    label = `£${value / 100} + C`;
  }
  if (!fixed && !value) {
    info = 'You just need to pay for the messaging credits';
    label = '£0 + C';
  }
  if (fixed && !value) {
    info = 'Free to play. The author is paying the messaging costs';
    label = 'Free';
  }
  return {
    info,
    label,
  };
};
