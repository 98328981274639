const SMS_SEGMENT = 160;

const tags = [
  {
    label: 'Adventure',
    id: 'adv',
    category: 'Format',
  },
  {
    label: 'Treasure Hunt',
    id: 'treas',
    category: 'Format',
  },
  {
    label: 'Quiz',
    id: 'quiz',
    category: 'Format',
  },
  {
    label: 'Role-Play',
    id: 'role',
    category: 'Format',
  },
  {
    label: 'Exercise',
    id: 'ex',
    category: 'Format',
  },
  {
    label: 'Guided Tour',
    id: 'guid',
    category: 'Format',
  },
  {
    label: 'Cycling',
    id: 'cyc',
    category: 'Accessibility',
  },
  {
    label: 'Wheelchair Friendly',
    id: 'wca',
    category: 'Accessibility',
  },
  {
    label: 'Pushchair Friendly',
    id: 'pca',
    category: 'Accessibility',
  },
  {
    label: 'Family',
    id: 'fam',
    category: 'Audience',
  },
  {
    label: 'Children',
    id: 'child',
    category: 'Audience',
  },
  {
    label: 'Adult',
    id: 'adult',
    category: 'Audience',
  },
  {
    label: 'Educational',
    id: 'edu',
    category: 'Theme',
  },
  {
    label: 'History',
    id: 'hist',
    category: 'Theme',
  },
  {
    label: 'Maths',
    id: 'math',
    category: 'Theme',
  },
  {
    label: 'Mystery',
    id: 'mys',
    category: 'Theme',
  },
  {
    label: 'Puzzle',
    id: 'puz',
    category: 'Theme',
  },
  {
    label: 'Art',
    id: 'art',
    category: 'Theme',
  },
  {
    label: 'Sponsored',
    id: 'spons',
    category: 'Pricing',
  },
];

export default {
  auth: {
    storageKey: 'faunaKey',
  },
  supportedCountries: {
    gb: {
      name: 'United Kingdom',
      prefix: '+44',
      code: 'gb',
    },
    es: {
      name: 'Spain',
      prefix: '+34',
      code: 'es',
    },
  },
  script: {
    step: {
      // Leaving some wiggle room for any text system may need to add e.g. when user PASS's
      maxLength: (SMS_SEGMENT * 3) - 30,
    },
    tags,
    tagsById: tags.reduce((acc, tag) => ({ ...acc, [tag.id]: tag }), {}),
  },
};
