import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const DELETE_SCRIPT = gql`
  mutation DeleteScript (
    $scriptId: ID!
  ) {
    partialUpdateScript(
      id: $scriptId
      data: {
        deleted: true
      }) {
      _id
    }
  }
`;


const useDeleteScript = (id, options = {}) => {
  const [deleteScript, { data, loading, error }] = useMutation(
    DELETE_SCRIPT,
    {
      variables: {
        scriptId: id,
      },
      ...options,
    },
  );

  return [
    useCallback(
      () => {
        deleteScript();
      },
      [deleteScript, id],
    ),
    { data, loading, error },
  ];
};

export default useDeleteScript;
