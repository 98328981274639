import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

import config from '../../config';
import { LocationSearchInput, SelectInput, TagInput } from '../UI/form';
import { Form, InputField, CheckboxField, CurrencyTextField, InfoTip } from '../UI';
import DraggableStep from './Step';
import validate from './validate';
import { SaveFab } from './ScriptForm.style';

const fixedPriceInfo = `The credits used for messaging will be deducted from you (the script author) rather than the player.

This means the player pays whatever price you set (if any) but you need to ensure your account has sufficient credit`;

const Answers = ({ fields, meta: { error } }) => (
  <Grid item xs={12}>
    {fields.map((answer, index) => (
      <Grid key={answer} item container direction="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={answer}
            fullWidth
            component={InputField}
            label="Answer"
            margin="dense"
            required
          />
        </Box>
        <IconButton
          aria-label="delete"
          title="Remove Answer"
          onClick={() => fields.remove(index)}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    ))}
    <div>
      <Button
        color="primary"
        onClick={() => fields.push()}
      >
        Add Answer
      </Button>
    </div>
    {error && <div className="error">{error}</div>}
  </Grid>
);

const Hints = ({ fields, meta: { error } }) => (
  <Grid item xs={12}>
    {fields.map((hint, index) => (
      <Grid key={hint} item container direction="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={hint}
            fullWidth
            component={InputField}
            label="Hint"
            margin="dense"
            required
            multiline
          />
        </Box>
        <IconButton
          aria-label="delete"
          title="Remove Hint"
          onClick={() => fields.remove(index)}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    ))}
    <div>
      <Button
        color="primary"
        onClick={() => fields.push('')}
      >
        Add Hint
      </Button>
    </div>
    {error && <Alert severity="error">{error}</Alert>}
  </Grid>
);

const Step = ({ step, index, fields, field }) => {
  const name = `${step}.clue`;
  return (
    <DraggableStep
      index={index}
      id={field.id}
      moveStep={fields.move}
      deleteStep={() => fields.remove(index)}
      canDelete={fields.length > 1}
    >
      <Grid item xs={12} container direction="row">
        <Box flexGrow={1}>
          <Field
            name={name}
            component={InputField}
            label={`Step ${index + 1}`}
            margin="dense"
            fullWidth
            required
            multiline
            placeholder={index === 0 ? 'Welcome to my awesome trail, head to X and reply with OK to get started' : ''}
            maxLength={config.script.step.maxLength}
          />
        </Box>
      </Grid>
      <FieldArray name={`${step}.answers`} component={Answers} />
      <FieldArray name={`${step}.hints`} component={Hints} />
    </DraggableStep>
  );
};

const Steps = ({ fields, meta: { touched, error, submitFailed } }) => (
  <Grid item xs={12}>
    {fields.map((step, index) => (
      <Step
        key={fields.get(index).id}
        fields={fields}
        step={step}
        index={index}
        field={fields.get(index)}
      />
    ))}
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fields.push({ clue: '', id: uniqueId() })}
      >
        Add Step
      </Button>
      {(touched || submitFailed) && error && <span>{error}</span>}
    </div>
  </Grid>
);

const ScriptForm = ({ handleSubmit, pristine, reset, submitting, scriptType }) => (
  <DndProvider backend={HTML5Backend}>
    <Form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field
            name="title"
            component={InputField}
            label="Title"
            fullWidth
            required
            margin="dense"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="description"
            fullWidth
            component={InputField}
            label="Description"
            margin="dense"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="tags"
            fullWidth
            component={TagInput}
            label="Tags"
            margin="dense"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="type"
            fullWidth
            component={SelectInput}
            label="Type"
            options={[
              {
                label: 'Clue Trail',
                value: 'TRAIL',
              },
              {
                label: 'Quiz',
                value: 'QUIZ',
              },
            ]}
          />
        </Grid>
        {scriptType === 'TRAIL' && (
          <>
            <Grid item xs={12} sm={6}>
              <Field
                name="startLocation"
                label="Start Location"
                component={LocationSearchInput}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="endLocation"
                label="End Location"
                component={LocationSearchInput}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={8}>
            <Field
              name="price"
              fullWidth
              component={CurrencyTextField}
              label="Price"
            />
          </Grid>
          <Grid item xs={4} container alignItems="center">
            <Field
              name="fixed"
              type="checkbox"
              component={CheckboxField}
              label="Fixed Price"
              margin="dense"
            />
            <InfoTip title={fixedPriceInfo} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <Field
            name="private"
            type="checkbox"
            component={CheckboxField}
            label="Private"
            margin="dense"
          />
          <InfoTip title="Private scripts do not show up in the trail finder so only you can see them and play or host them" />
        </Grid>
        <FieldArray name="steps" component={Steps} />
        <Grid item xs={12}>
          <Field
            name="goodbye"
            fullWidth
            component={InputField}
            label="Completed message"
            margin="dense"
            multiline
          />
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Tooltip title={submitting ? 'Saving script' : 'Save script'}>
              <SaveFab
                type="submit"
                color="primary"
                disabled={submitting}
              >
                {submitting ? <CircularProgress color="secondary" /> : <SaveIcon />}
              </SaveFab>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  </DndProvider>
);

const WrappedForm = reduxForm({
  form: 'script',
  validate,
})(ScriptForm);

export default connect(
  (state) => ({
    scriptType: formValueSelector('script')(state, 'type'),
  }),
)(WrappedForm);
