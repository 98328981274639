import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthContext, useQueryParams } from '../../hooks';

const URLQueryHandler = () => {
  const { actions } = useAuthContext();
  const history = useHistory();
  const queryParams = useQueryParams();

  useEffect(
    // This parses and base64 decodes the payload in url (if present) and passes it to the
    // AuthModal via showModal then removes the query string from the url so can do e.g.
    // ?auth=login&phoneNumber=07896410090 to open login modal with number populated
    () => {
      if (queryParams.auth) try {
        // TODO: Move this to the useQuery hook
        const { auth, ...args } = queryParams;
        actions.showModal(
          auth,
          args.payload ? JSON.parse(atob(args.payload)) : args,
        );
      }
      catch (e) {
        console.log('Failed to parse and decode query string', e);
      }
      finally {
        history.replace('/');
      }
    },
    [actions, queryParams],
  );

  return null;
};

export default URLQueryHandler;
