import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import Scripts from './scripts';
import Script from './script';
import Trails from './trails';
import ScriptMaker from './scripts/add';
import Home from './root';
import PlayedGames from './play';
import PlayedGameDetail from './play/game';
import HostedGames from './host';
import HostedGameDetail from './host/game';
import Topup from './topup';
import NotFound from './404';
import Pricing from './pricing';
import User from './user'
import PrivateRoute from '../components/PrivateRoute';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/trails" exact>
          <Trails />
        </Route>
        <Route path="/u/:userName" exact>
          <User />
        </Route>
        <Route path="/scripts">
          <Switch>
            <Route path="/scripts" exact>
              <Scripts />
            </Route>
            <Route path="/scripts/add">
              <ScriptMaker />
            </Route>
            <Route path="/scripts/:scriptId">
              <Script />
            </Route>
          </Switch>
        </Route>
        <PrivateRoute path="/host">
          <Switch>
            <Route path="/host" exact>
              <HostedGames />
            </Route>
            <Route path="/host/:gameId">
              <HostedGameDetail />
            </Route>
          </Switch>
        </PrivateRoute>
        <PrivateRoute path="/play">
          <Switch>
            <Route path="/play" exact>
              <PlayedGames />
            </Route>
            <Route path="/play/:gameId">
              <PlayedGameDetail />
            </Route>
          </Switch>
        </PrivateRoute>
        <Route path="/topup">
          <Topup />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/">
          <Home />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
