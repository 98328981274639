import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Form, Avatar, InputField } from '../../UI';
import { PhoneNumberInput } from '../../UI/form';
import { Submit } from './AuthModal.style';

const validate = ({ phoneNumber = '', password = '' }) => {
  const errors = {};
  // TODO: Handle other countries phone numbers
  const phone = parsePhoneNumberFromString(phoneNumber);
  if (!phone || !phone.isValid()) errors.phoneNumber = 'Not a valid mobile number';
  if (password.length < 8) errors.password = 'Must be at least 8 characters';
  return errors;
};

function LoginForm({ handleSubmit, submitting, actions }) {
  return (
    <>
      <Avatar>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Form noValidate onSubmit={handleSubmit}>
        <Field
          name="phoneNumber"
          placeholder="+447888100100"
          type="text"
          component={PhoneNumberInput}
          label="Phone Number"
          autoComplete="phone"
          autoFocus
          fullWidth
          id="phoneNumber"
        />
        <Field
          name="password"
          type="password"
          component={InputField}
          label="Password"
          autoComplete="current-password"
          fullWidth
        />
        <Submit
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Sign In
          {submitting && <CircularProgress size={20} color="white" />}
        </Submit>
      </Form>
      <Grid container>
        <Grid item xs>
          <Link component="button" onClick={() => actions.showModal('forgot')}>
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link component="button" onClick={() => actions.showModal('signup')}>
            Don't have an account? Sign Up
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default reduxForm({
  form: 'login',
  validate,
})(LoginForm);
