/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import { Paper, Link, Price } from '../../components/UI';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

export const GET_SCRIPTS = gql`
  query getScripts {
    allLiveScripts {
      data {
        _id
        title
        description
        private
        steps {
          clue
        }
        games {
          data {
            status
          }
        }
        price {
          value
          fixed
        }
      }
    }
  }
`;

const Scripts = () => {

  const {
    loading, error, data: response,
  } = useQuery(GET_SCRIPTS, { fetchPolicy: 'cache-and-network' });

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: '_id',
      },
      {
        Header: 'Private',
        accessor: 'private',
      },
      {
        Header: 'Name',
        accessor: 'title',
        Cell: ({ value, row }) => (
          <Link to={`/scripts/${row.values._id}`}>
            {value}
            {row.values.private && (
              <Tooltip title="Private">
                <LockIcon fontSize="inherit" />
              </Tooltip>
            )}
          </Link>
        ),
        id: 'title',
      },
      {
        Header: 'Plays',
        accessor: (row) => get(row, ['games', 'data', 'length'], 0),
        sortType: 'basic',
        id: 'plays',
      },
      {
        Header: 'Price',
        accessor: (row) => get(row, ['price', 'value']),
        id: 'price',
        Cell: ({ value, row }) => <Price value={value} fixed={get(row, ['original', 'price', 'fixed'], false)} />,
      },
    ],
    [],
  );

  const data = useMemo(
    () => get(response, ['allLiveScripts', 'data'], []),
    [response],
  );

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        'private',
        '_id',
      ],
    }),
    [],
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    initialState,
  }, useGlobalFilter, useSortBy);

  return (
    <Layout>
      <SEO title="Scripts | Scriptic" />
      <Paper>
        <Typography variant="h4">Your Scripts</Typography>
        <p>
          Here you should see a list of all your own scripts that you've created
        </p>
        <p>
          Not made any yet? You can start
          {' '}
          <Link to="/scripts/add">here</Link>
          {' '}
        </p>
        <TextField type="search" onChange={(e) => setGlobalFilter(e.target.value)} label="Search" />
        <div>
          {loading && <CircularProgress size={20} />}
          {!!data.length && (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? (column.isSortedDesc ? ' 🔽' : ' 🔼')
                              : ''}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {error && <div>{error.message}</div>}
        </div>
      </Paper>
    </Layout>
  );
};

export default Scripts;
