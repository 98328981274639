import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { getErrorMessage } from '../../utils';
import { useFetch, useAuthContext } from '../../hooks';

const StartScript = ({ script }) => {
  const history = useHistory();
  const { phoneNumber: playerNumber, name: playerName } = useAuthContext();
  const [startScript, startingScript, response] = useFetch({ url: '/.netlify/functions/games/start' });

  const scriptId = script._id;

  const handleStartScript = useCallback(
    () => startScript({ data: { scriptId, playerNumber, playerName } }),
    [scriptId, playerNumber, playerName, startScript],
  );

  const closeModal = useCallback(
    () => history.replace(`/scripts/${scriptId}`),
    [history, scriptId],
  );

  useEffect(
    () => {
      const id = get(response, ['data', 'id']);
      if (id) history.push(`/play/${id}`);
    },
    [history, response],
  );

  return (
    <Dialog open onClose={closeModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{`Play ${script.title}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Do you want to play this script on your registered number ${playerNumber}?`}
        </DialogContentText>
        {response && response.error && (
          <Alert severity="error">{getErrorMessage(response.error)}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={startingScript} onClick={handleStartScript} variant="contained" color="primary">
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartScript;
