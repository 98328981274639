/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import round from 'lodash/round';

import CircularProgress from '@material-ui/core/CircularProgress';

import config from '../../config';
import GoogleMap from '../../components/GoogleMap';

// We can't use styled components inside the InfoWindow
const renderInfoWindow = ({ title, stats, tags, _id }) => (
  <>
    <a href={`/scripts/${_id}`}><strong>{title}</strong></a>
    {stats.rating && <p>{round(stats.rating, 1)}/5⭑</p>}
    <p>
      {tags.map((tag) => (
        <span>
          {config.script.tags.find((t) => t.id === tag).label}
          /
        </span>
      ))}
    </p>
  </>
);

const MapResults = ({ data, error, loading }) => {
  const markers = useMemo(
    () => data
      .filter((trail) => trail.startLocation && trail.startLocation.coordinates)
      .map(({ title, startLocation, stats, tags, _id }) => ({
        title,
        position: startLocation.coordinates,
        stats,
        tags,
        _id,
      })),
    [data],
  );
  return (
    <div style={{ marginTop: '10px' }}>
      {loading && <CircularProgress size={20} />}
      {!data.length && !loading && <span>No results found with selected filters</span>}
      {markers.length !== data.length && (
        <span>
          There are more matching results in list mode
        </span>
      )}
      {!!markers.length && (
        <GoogleMap
          renderInfo={renderInfoWindow}
          markers={markers}
        />
      )}
      {error && <div>{error.message}</div>}
    </div>
  );
};

export default MapResults;
