/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useTable, useSortBy } from 'react-table';
import round from 'lodash/round';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import { formatMetres } from '../../utils';
import { Link, Price } from '../../components/UI';

const Scripts = ({ data, error, loading, isGeoSearch }) => {

  const columns = useMemo(
    () => {
      const cols = [
        {
          Header: 'ID',
          accessor: '_id',
        },
        {
          Header: 'Private',
          accessor: 'private',
        },
        {
          Header: 'Name',
          accessor: 'title',
          Cell: ({ value, row }) => (
            <Link to={`/scripts/${row.values._id}`}>
              {value}
              {row.values.private && (
                <Tooltip title="Private">
                  <LockIcon fontSize="inherit" />
                </Tooltip>
              )}
            </Link>
          ),
          id: 'title',
        },
        {
          Header: 'Plays',
          accessor: (row) => get(row, ['stats', 'started'], 0),
          sortType: 'basic',
          id: 'plays',
        },
        {
          Header: 'Rating',
          accessor: (row) => get(row, ['stats', 'rating'], null),
          id: 'rating',
          Cell: ({ value, row }) => (
            <span>
              {value ? `${round(value, 1)} / 5` : '?'}
            </span>
          ),
        },
        {
          Header: 'Price',
          accessor: (row) => get(row, ['price', 'value']),
          id: 'price',
          Cell: ({ value, row }) => <Price value={value} fixed={get(row, ['original', 'price', 'fixed'], false)} />,
        },
      ];
      if (isGeoSearch) cols.push({
        Header: 'Distance',
        accessor: 'distance',
        Cell: ({ value }) => (
          <span>
            {value !== null ? formatMetres(value) : 'NA'}
          </span>
        ),
      });
      return cols;
    },
    [isGeoSearch],
  );

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        'private',
        '_id',
      ],
      sortBy: [
        {
          id: 'rating',
          desc: true,
        },
      ],
    }),
    [],
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState,
  }, useSortBy);

  return (
    <div>
      {loading && <CircularProgress size={20} />}
      {!data.length && !loading && <span>No results found with selected filters</span>}
      {!!data.length && (
        <TableContainer>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? (column.isSortedDesc ? ' 🔽' : ' 🔼')
                          : ''}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {error && <div>{error.message}</div>}
    </div>
  );
};

export default Scripts;
