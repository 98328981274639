import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const StartScript = () => {
  const history = useHistory();

  const closeModal = useCallback(
    () => history.replace('/topup'),
    [history],
  );

  return (
    <Dialog open onClose={closeModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Purchase Completed</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Than-you for your purchase, you should see your new credits added to your balance immediately
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartScript;
