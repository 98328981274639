import React from 'react';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiPaper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import MuiChip from '@material-ui/core/Chip';
import CurrencyField from '@unicef/material-ui-currency-textfield';
import MuiIconButton from '@material-ui/core/IconButton';

export { default as Price } from './Price';
export { default as ButtonDialog } from './ButtonDialog';
export { default as InfoTip } from './InfoTip';

export const Paper = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const Avatar = styled(MuiAvatar)`
  margin: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Form = styled.form`
  width: 100%; // Fix IE 11 issue.
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const InputField = ({
  input, meta: { touched, error }, maxLength, ...props
}) => {
  const remaining = input.value.length && maxLength
    ? `${input.value.length}/${maxLength}`
    : undefined;
  return (
    <TextField
      margin="dense"
      id={input.name}
      inputProps={{
        ...input,
        maxLength,
      }}
      error={touched && !!error}
      helperText={touched && error ? error : remaining}
      variant="standard"
      {...props}
    />
  );
};

export const CurrencyTextField = ({
  input, meta: { touched, error }, ...props
}) => (
  <CurrencyField
    margin="dense"
    id={input.name}
    inputProps={input}
    error={touched && !!error}
    helperText={touched && error}
    variant="standard"
    currencySymbol="£"
    {...props}
  />
);

export const CheckboxField = ({
  input, meta: { touched, error }, label, color = 'primary', ...props
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        color={color}
        {...input}
      />
    )}
    label={label}
  />
);

export const Link = ({ children, ...props }) => (
  <MuiLink component={RouterLink} {...props}>
    {children}
  </MuiLink>
);

export const Message = styled.div`
  padding: 4px 10px;
  border-radius: 14px;
  white-space: pre-wrap;
  background-color: ${({ received, theme }) => (received ? theme.palette.secondary.main : theme.palette.primary.main)};
  max-width: 250px;
  color: white;
  ${({ received, theme }) => (received ? `margin-left: ${theme.spacing(2)}px` : `margin-right: ${theme.spacing(2)}px`)};
  margin-bottom: 4px;
`;

export const ButtonLink = ({ children, ...props }) => (
  <Button component={RouterLink} {...props}>
    {children}
  </Button>
);

export const Messenger = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(2, 2, 1, 2)};
`;

export const CreditChip = ({ credits, color = 'default' }) => (
  <MuiChip
    avatar={<MuiAvatar color="inherit">C</MuiAvatar>}
    variant="outlined"
    label={credits}
    size="small"
    color={color}
  />
);

export const Chip = styled(MuiChip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  &:last-of-type {
    margin: 0;
  }
`;

export const GridListTileBar = styled.div`
  left: 0;
  right: 0;
  bottom: 5px; // Not sure why this is needed
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);
  color: white;
`;

export const GridList = styled.ul`
  flex-wrap: nowrap;
  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: translateZ(0);
  list-style-type: none;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 0;
`;

export const StarIconButton = styled(MuiIconButton)`
  color: gold;
`;

export const BinIconButton = styled(MuiIconButton)`
  color: white;
`;
