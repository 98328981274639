import React, { useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import parseJWT from 'jwt-decode';
import * as Sentry from '@sentry/browser';

import AuthModal from './AuthModal';
import { getErrorMessage, formatMobileNumber } from '../../utils';
import config from '../../config';

const initialState = {
  name: '',
  pending: false,
  error: '',
};

// Grrrr https://github.com/axios/axios/issues/960
axios.interceptors.response.use(undefined, (error) => {
  error.originalMessage = error.message;
  Object.defineProperty(error, 'message', { get() {
    if (!error.response) return error.originalMessage;

    return error.response.data;
  } });
  return Promise.reject(error);
});

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [state, setState] = useState(initialState);
  const [mode, setMode] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({});

  const setJWT = useCallback(
    (jwt, prevState, nextState = {}) => {
      localStorage.setItem(config.auth.storageKey, jwt);
      axios.defaults.headers.post.Authorization = jwt;
      axios.defaults.headers.get.Authorization = jwt;
      axios.defaults.headers.delete.Authorization = jwt;
      const claims = parseJWT(jwt);
      setState({
        ...prevState,
        ...nextState,
        ...claims,
        loggedIn: true,
        error: '',
        jwt,
      });
      return claims;
    },
    [setState, axios],
  );

  // Get cached jwt and decode into state on mount
  useEffect(() => {
    const jwt = localStorage.getItem(config.auth.storageKey);
    if (!jwt) return;
    setJWT(jwt, state);
  }, []);

  const showModal = useCallback(
    (nextMode, initialValues) => {
      setState({
        ...state,
        error: '',
      });
      // Close modal if null passed
      if (nextMode === null) return setMode(null);

      // We can't use a default param here as this can be passed directly
      // to onClick which would pass an event
      const defaultMode = state.name ? 'logout' : 'login';
      if (initialValues) setInitialFormValues(initialValues);
      return setMode(typeof nextMode === 'string' ? nextMode : defaultMode);
    },
    [state],
  );

  const login = useCallback(
    async (creds) => {
      setState({
        ...state,
        pending: true,
        error: '',
      });
      try {
        const phoneNumber = formatMobileNumber(creds.phoneNumber);
        const response = await axios.post(
          '/.netlify/functions/auth/login',
          {
            ...creds,
            phoneNumber,
          },
        );
        setJWT(response.data, state, { pending: false });
        setMode(null);
      }
      catch (e) {
        Sentry.captureException(e);
        setState({
          ...state,
          pending: false,
          error: getErrorMessage(e, 'Something went awry when logging in'),
        });
      }
    },
    [state],
  );

  const logout = useCallback(
    async () => {
      try {
        setState({ ...state, pending: true });
        await axios('/.netlify/functions/auth/logout');
      }
      catch (e) {
        Sentry.captureException(e);
      }
      finally {
        localStorage.removeItem(config.auth.storageKey);
        await window.apolloClient.clearStore();
        await window.apolloClient.resetStore();
        setState(initialState);
        setMode(null);
      }
    },
    [state],
  );

  const refreshJWT = useCallback(
    async () => {
      try {
        const { data: jwt } = await axios('/.netlify/functions/auth/refresh');
        setJWT(jwt, state, { loggedIn: true, pending: false });
      }
      catch (e) {
        await logout();
        showModal('login');
      }
    },
    [setState],
  );

  const signup = useCallback(
    async (creds) => {
      setState({
        ...state,
        pending: true,
        error: '',
      });
      try {
        // We convert the validated mobile number string into international (E.164) format
        const phoneNumber = formatMobileNumber(creds.phoneNumber);
        const response = await axios.post(
          '/.netlify/functions/auth/register',
          {
            ...creds,
            phoneNumber,
          },
        );
        const claims = setJWT(response.data, state, { pending: false });
        setMode(claims.phoneVerified ? null : 'verify');
      }
      catch (e) {
        Sentry.captureException(e);
        setState({
          ...state,
          pending: false,
          error: getErrorMessage(e, 'Something went wrong with signup'),
        });
      }
    },
    [state],
  );

  const verify = useCallback(
    async ({ code }) => {
      setState({
        ...state,
        pending: true,
        error: '',
      });
      try {
        await axios.post(
          '/.netlify/functions/auth/phone/verify',
          { code, phoneNumber: state.phoneNumber },
        );
        setState({
          ...state,
          pending: false,
          error: '',
        });
        setMode(null);
      }
      catch (e) {
        Sentry.captureException(e);
        setState({
          ...state,
          pending: false,
          error: getErrorMessage(e, 'The verification step failed'),
        });
      }
    },
    [state],
  );

  const requestPasswordReset = useCallback(
    async (values) => {
      setState({
        ...state,
        pending: true,
        error: '',
      });
      try {
        await axios.post(
          '/.netlify/functions/auth/password/reset',
          { phoneNumber: formatMobileNumber(values.phoneNumber) },
        );
        setState({
          ...state,
          pending: false,
          error: '',
        });
        setMode(null);
      }
      catch (e) {
        Sentry.captureException(e);
        setState({
          ...state,
          pending: false,
          error: getErrorMessage(e, 'The password reset request failed'),
        });
      }
    },
    [state],
  );

  const passwordReset = useCallback(
    async ({ password, token }) => {
      setState({
        ...state,
        pending: true,
        error: '',
      });
      try {
        await axios.post('/.netlify/functions/auth/password/change', { password, token });
        setState({
          ...state,
          pending: false,
          error: '',
        });
        setMode('login');
      }
      catch (e) {
        Sentry.captureException(e);
        setState({
          ...state,
          pending: false,
          error: getErrorMessage(e, 'The password reset failed'),
        });
      }
    },
    [state],
  );

  const actions = useMemo(
    () => ({
      login,
      logout,
      signup,
      verify,
      showModal,
      requestPasswordReset,
      passwordReset,
      refreshJWT,
    }),
    [login, logout, signup, verify, showModal, requestPasswordReset, passwordReset, refreshJWT],
  );

  return (
    <AuthContext.Provider value={{ ...state, loggedIn: !!state.name, actions }}>
      <AuthModal
        visible={!!mode}
        onClose={() => setMode(null)}
        actions={actions}
        mode={mode}
        initialValues={initialFormValues}
        {...state}
      />
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
