import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { getErrorMessage, formifyScript, deformifyScript } from '../../utils';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ScriptForm from '../../components/ScriptForm';
import { Paper, Link } from '../../components/UI';

const CREATE_SCRIPT = gql`
  mutation EditScript (
    $scriptId: ID!
    $title: String!
    $description: String
    $steps: [PartialUpdateStepInput!]!
    $private: Boolean!
    $price: PartialUpdatePriceInput!
    $welcome: String
    $goodbye: String
    $startLocation: PartialUpdateLocationInput
    $tags: [String!]
    $type: ScriptType!
  ) {
    partialUpdateScript(
      id: $scriptId
      data: {
        title: $title
        description: $description
        welcome: $welcome
        goodbye: $goodbye
        steps: $steps
        private: $private
        price: $price
        startLocation: $startLocation
        tags: $tags
        type: $type
      }) {
      _id
    }
  }
`;

const EditScript = ({ script, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [editScript, { error }] = useMutation(
    CREATE_SCRIPT,
    {
      onCompleted: () => setOpen(true),
    },
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleSubmit = useCallback(
    async (formValues) => {
      const mutationVars = await deformifyScript(formValues);
      await editScript({
        variables: {
          ...mutationVars, // Remove __typename and id properties
          scriptId: script._id,
        },
      });
      // We refetch the form here though this could be done with a optimistic
      // update based on the mutationVars instead
      await refetch();
    },
    [editScript, setOpen, script._id],
  );

  return (
    <Layout>
      <SEO title={`Edit | ${script.title}`} />
      <Paper>
        <Typography variant="h4">{`Edit ${script.title}`}</Typography>
        <Typography variant="p" paragraph>
          When you've made your changes just hit SAVE and you should be good to go
        </Typography>
        <ScriptForm onSubmit={handleSubmit} initialValues={formifyScript(script)} />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
            Changes saved{' '}
            <Link to={`/scripts/${script._id}`}>Play now?</Link>
          </Alert>
        </Snackbar>
        {error && (
          <Alert severity="error">{getErrorMessage(error)}</Alert>
        )}
      </Paper>
    </Layout>
  );
};
// TODO: Sort out error response shape madness
export default React.memo(EditScript);
