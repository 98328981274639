import React from 'react';

export default function () {
  return (
    <footer style={{ marginTop: '1rem' }}>
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      Scriptic Ltd
    </footer>
  );
}
