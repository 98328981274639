import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InputField, Form, Avatar } from '../../UI';
import { PhoneNumberInput } from '../../UI/form';
import { Submit } from './AuthModal.style';

const validate = ({ phoneNumber = '', name = '', password = '', verifyPassword = '' }) => {
  const errors = {};
  // We just check it's a valid mobile number as twilio will block non-supported numbers
  const phone = parsePhoneNumberFromString(phoneNumber);
  if (!phone || !phone.isValid()) errors.phoneNumber = 'Not a valid mobile number';
  if (!name.length) errors.name = 'Required';
  // TODO: Validate passwords better
  if (password.length < 8) errors.password = 'Must be at least 8 characters';
  if (verifyPassword !== password) errors.verifyPassword = 'Passwords don\'t match';
  return errors;
};

const SignupForm = ({ handleSubmit, submitting, actions }) => (
  <>
    <Avatar>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Sign Up
    </Typography>
    <Form onSubmit={handleSubmit}>
      <Field
        name="name"
        type="text"
        component={InputField}
        label="Display Name"
        fullWidth
        autoFocus
      />
      <Field
        id="phoneNumber"
        name="phoneNumber"
        placeholder="07888100100"
        type="text"
        component={PhoneNumberInput}
        label="Phone Number"
        fullWidth
      />
      <Field
        name="password"
        type="password"
        component={InputField}
        label="Password"
        fullWidth
      />
      <Field
        name="verifyPassword"
        type="password"
        component={InputField}
        label="Verify Password"
        fullWidth
      />
      <Submit
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting}
      >
        Sign Up
        {submitting && <CircularProgress size={20} color="secondary" />}
      </Submit>
    </Form>
    <Grid container>
      <Grid item>
        <Link component="button" onClick={() => actions.showModal('login')}>
          {"Already registered? Log In"}
        </Link>
      </Grid>
    </Grid>
  </>
);

export default reduxForm({
  form: 'signup',
  validate,
})(SignupForm);
