import { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useImage as useCloudImage } from 'use-cloudinary';

import AuthContext from '../components/AuthProvider';

export { default as useDeleteScript } from './useDeleteScript';

export function useAuthContext() {
  return useContext(AuthContext);
}

export function useFetch({ method = 'POST', throwOnError, ...config }) {
  const { jwt, actions } = useAuthContext();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const initiator = useCallback(
    async (nextConfig = {}) => {
      const originalRequestConfig = {
        method,
        ...config,
        ...nextConfig,
      };
      setLoading(true);
      let response;
      try {
        response = await axios(originalRequestConfig);
        setData(response);
      }
      catch (e) {
        if (e.response.status === 401) {
          await actions.refreshJWT();
          try {
            response = await axios(originalRequestConfig);
            setData(response);
          }
          catch (error) {
            if (throwOnError) throw error;
            setData({ error: { message: error } });
          }
        }
        else {
          if (throwOnError) throw e;
          setData({ error: { message: e } });
        }
      }
      setLoading(false);
      return response;
    },
    [method, config, jwt, actions],
  );
  return [initiator, loading, data];
}

export function useQueryParams() {
  return qs.parse(useLocation().search);
}

export function useImage(publicId, { height, width, transformations = [] }) {
  const { generateUrl, url, isLoading, isError, error } = useCloudImage({ cloudName: 'scriptic' });
  useEffect(() => {
    generateUrl({
      publicId,
      transformations: {
        height,
        width: 'auto',
        ...transformations,
      },
    });
  }, [publicId]);
  return { url, isLoading, isError, error };
}
