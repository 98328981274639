import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Layout from '../../components/layout';
import { Paper } from '../../components/UI';
import SEO from '../../components/seo';

const Pricing = () => (
  <Layout>
    <SEO title="Pricing | Scriptic" />
    <Paper>
      <Typography variant="h4">Pricing</Typography>
      <p>Scriptic's main cost is the SMS messages sent and received so we've gone for a pay per use model that takes a small margin to cover the costs of the messaging itself plus all the usual web hosting costs and hopefully, one day, the development costs</p>
      <p>To that end there are a few different ways you can offer trails to play on the platform with different effects for player and author</p>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Option
              </TableCell>
              <TableCell>
                Player
              </TableCell>
              <TableCell>
                Author
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                Script Fee
              </TableCell>
              <TableCell>
                Pays the script fee set and the credits used for messaging are deducted from their balance
              </TableCell>
              <TableCell>
                The author gets the script fee (minus 10%)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Fixed Price Script Fee
              </TableCell>
              <TableCell>
                Player pays the script fee set by author only with no extra credit costs
              </TableCell>
              <TableCell>
                The author gets the script fee (minus 10%) and credits used by the player are deducted from their account
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                No Script Fee
              </TableCell>
              <TableCell>
                Player can play the script without cash payment but need to pay credits for messages sent and received
              </TableCell>
              <TableCell>
                The author gets nothing but the satisfaction of spreading joy throughout the land (and/or any sponsorship money they might be blagging)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Free
              </TableCell>
              <TableCell>
                Free to play, no script fee and no credits deducted
              </TableCell>
              <TableCell>
                Funds the credit costs of messaging and get's no cash pay out (unless monetising it in other ways)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </Layout>
);

export default Pricing;
