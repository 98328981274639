import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import ClearAll from '@material-ui/icons/ClearAll';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import GameEvents from '../../components/GameEvents';
import Layout from '../../components/layout';
import { Paper, CreditChip, ButtonDialog, Link } from '../../components/UI';
import { useAuthContext, useFetch } from '../../hooks';

export const GET_GAME = gql`
  query getGame($id: ID!) {
    findGameByID(id: $id) {
      script {
        _id
        title
        description
        private
        steps {
          clue
        }
      }
      status
      state {
        step
        hint
      }
      events {
        data {
          _ts
          payload
          eventType
        }
      }
      player {
        _id
        name
      }
    }
  }
`;

const Game = () => {
  const { gameId } = useParams();
  const { userId } = useAuthContext();
  const isValid = gameId && Number.isInteger(+gameId);

  const {
    loading, error, data, refetch,
  } = useQuery(GET_GAME, { variables: { id: gameId }, skip: !isValid });

  const [stopGame] = useFetch({
    url: `/.netlify/functions/games/${gameId}/stop`,
    throwOnError: true,
  });

  if (!isValid) return (
    <Layout>
      The game ID in URL (
      {gameId}
      ) appears to be invalid
    </Layout>
  );
  if (loading) return <Layout><CircularProgress /></Layout>;
  if (error) return <Layout>{error}</Layout>;
  if (!data || !data.findGameByID) return <Layout>No game found for that id, check URL</Layout>;

  const { findGameByID: game } = data;
  const creditsSpent = game.events.data
    .filter((event) => event.eventType !== 'SYSTEM_SMS_SENT')
    .length;

  return (
    <Layout>
      <Paper>
        <Link to="/host">Hosted games</Link>
        <Box mb={1}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">{game.script.title}</Typography>
              <Typography variant="h6">
                {`Played by ${userId === game.player._id ? 'you' : game.player.name}`}
              </Typography>
            </Grid>
            <Grid item>
              {game.status !== 'ACTIVE'
                ? (
                  <Chip
                    icon={<DoneIcon />}
                    variant="outlined"
                    label="Ended"
                    size="small"
                  />
                )
                : (
                  <Chip
                    icon={<ClearAll />}
                    variant="outlined"
                    label={`Step ${game.state.step + 1}/${game.script.steps.length}`}
                    size="small"
                  />
                )}
              <CreditChip credits={-creditsSpent} />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <GameEvents showSender={game.status === 'ACTIVE'} refetch={refetch} gameId={gameId} events={game.events.data} />
              {error && <Alert severity="error">{error.message}</Alert>}
            </Grid>
            <Grid item xs={12} sm={6}>
              {game.status === 'ACTIVE' && (
                <ButtonDialog
                  title="End game"
                  content="Are you sure you want to end this game now?"
                  label="Yes"
                  action={stopGame}
                  onAccepted={refetch}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
};

export default Game;
