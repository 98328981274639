import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import ChatIcon from '@material-ui/icons/Chat';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersonIcon from '@material-ui/icons/Person';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ButtonLink } from '../UI';
import { useAuthContext } from '../../hooks';
import { Content, HomeLink, NavButton, NavLink, CreditChip } from './Header.style';
import ResponsiveMenu from './ResponsiveMenu';

export const GET_USER = gql`
  query User($id: ID!) {
    findUserAccountByID(id: $id) {
      credits
    }
  }
`;

const Header = () => {
  const { loggedIn, accountId, actions, name } = useAuthContext();
  const { data } = useQuery(GET_USER, { variables: { id: accountId }, skip: !accountId });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const matches = useMediaQuery('(min-width:500px)');

  return (
    <AppBar position="static">
      <Container>
        <Content>
          <Toolbar disableGutters>
            <HomeLink to="/">
              <ChatIcon />
            </HomeLink>
            {matches && (
              <>
                <NavLink to="/trails">
                  Play
                </NavLink>
                <NavLink to="/scripts/add">
                  Create
                </NavLink>
                {loggedIn && (
                  <>
                    <NavLink to="/scripts">
                      Scripts
                    </NavLink>
                    <div>
                      <NavButton aria-controls="games-menu" aria-haspopup="true" onClick={handleClick}>
                        Games
                      </NavButton>
                      <Menu
                        id="games-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                      >
                        <MenuItem>
                          <ButtonLink to="/host">
                            Hosted
                          </ButtonLink>
                        </MenuItem>
                        <MenuItem>
                          <ButtonLink to="/play">
                            Played
                          </ButtonLink>
                        </MenuItem>
                      </Menu>
                    </div>
                  </>
                )}
              </>
            )}
          </Toolbar>
          {matches
            ? (
              <Toolbar disableGutters>
                {data && data.findUserAccountByID && (
                  <NavLink to="/topup">
                    <CreditChip
                      variant="outlined"
                      size="small"
                      avatar={<Avatar>C</Avatar>}
                      label={data.findUserAccountByID.credits}
                    />
                  </NavLink>
                )}
                <Tooltip title={name || 'Log in'}>
                  <IconButton onClick={actions.showModal} color="inherit" aria-label="menu">
                    <PersonIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            )
            : <ResponsiveMenu data={data} />}
        </Content>
      </Container>
    </AppBar>
  );
};

export default Header;
