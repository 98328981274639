import React from 'react';
import noop from 'lodash/noop';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import AlertDialog from '../AlertDialog';

const ButtonDialog = ({ Icon, label, title, content, action, onAccepted = noop, children }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [pending, setPending] = React.useState(false);
  const handleAccept = React.useCallback(
    async () => {
      setPending(true);
      setError('');
      try {
        await action();
        setPending(false);
        onAccepted();
        setOpen(false);
      }
      catch (e) {
        setPending(false);
        setError(e.message);
      }
    },
    [action, onAccepted],
  );
  return (
    <>
      {children
        ? children({ handleClick: () => setOpen(true) })
        : (
          <Button variant="contained" onClick={() => setOpen(true)}>
            {Icon && <Icon />}
            {title || label}
          </Button>
        )}
      <AlertDialog
        title={title}
        content={content}
        open={open}
        handleClose={() => setOpen(false)}
        error={error}
      >
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAccept} disabled={pending} color="primary">
          {pending ? <CircularProgress size={20} /> : label}
        </Button>
      </AlertDialog>
    </>
  );
};

export default ButtonDialog;
