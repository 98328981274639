import React from 'react';
import { Route } from 'react-router-dom';
import { useAuthContext } from '../../hooks';

import PrivateRoutePreview from './PrivateRoutePreview';

const PrivateRoute = ({ children, noFallback = false, ...rest }) => {
  const { loggedIn } = useAuthContext();
  if (!loggedIn) return noFallback ? null : <PrivateRoutePreview />;
  return (
    <Route {...rest}>
      {children}
    </Route>
  );
};

export default PrivateRoute;
