import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import { Paper } from '../UI';

export const StepWrapper = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  &:last-of-type {
    margin: 0;
  }
  ${({ isDragging }) => (isDragging ? 'opacity: 0;' : '1;')}
`;

export const FieldContainer = styled.div`
  flex: 1;
`;

export const Grip = styled.div`
  cursor: move;
  display: flex;
  align-items: center;
`;

export const SaveFab = styled(Fab)`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(2)}px;
  right: ${({ theme }) => theme.spacing(2)}px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
