import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const InfoTip = ({ title }) => (
  <Tooltip title={title} enterTouchDelay={0}>
    <InfoIcon color="primary" />
  </Tooltip>
);

export default InfoTip;
