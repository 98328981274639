import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import round from 'lodash/round';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PersonIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Settings';
import ClearAll from '@material-ui/icons/ClearAll';
import StarIcon from '@material-ui/icons/Star';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';

import config from '../../../config';
import GoogleMap from '../../../components/GoogleMap';
import AlertDialog from '../../../components/AlertDialog';
import { Paper, ButtonLink, Chip, Link } from '../../../components/UI';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { useAuthContext, useDeleteScript } from '../../../hooks';
import { getPriceLabels } from '../../../utils';

import ScriptImages from './ImageGallery';

const ScriptDetail = ({ script, refetch }) => {
  const { title, description, games, steps, author, _id, price, stats } = script;
  const { userId, loggedIn } = useAuthContext();
  const isAuthor = author._id === userId;
  const { pathname } = useLocation();
  const history = useHistory();
  const [handleDelete] = useDeleteScript(_id, { onCompleted: () => history.push('/scripts') });
  const [deleteDialogIsOpen, setDeleteDialogOpen] = React.useState(false);

  return (
    <Layout>
      <SEO title={`${title} | Scriptic`} />
      <Paper>
        <Box mb={1}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid item>
              <Chip
                size="small"
                icon={<ClearAll />}
                variant="outlined"
                label={`${steps.length} Steps`}
              />
              <Chip
                size="small"
                icon={<PlayIcon />}
                variant="outlined"
                label={`${games.data.length} Plays`}
              />
              <Tooltip title={getPriceLabels(price).info}>
                <Chip
                  size="small"
                  variant="outlined"
                  label={getPriceLabels(price).label}
                />
              </Tooltip>
              {!!stats.rating && (
                <Chip
                  size="small"
                  icon={<StarIcon />}
                  variant="outlined"
                  label={round(stats.rating, 1)}
                />
              )}
            </Grid>
          </Grid>
          <Link to={`/u/${author.name}`}>
            <Typography variant="h6">{isAuthor ? 'me' : author.name}</Typography>
          </Link>
        </Box>
        <Divider />
        <Box pt={2} pb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ScriptImages
                scriptId={_id}
                images={script.images.data}
                primaryImage={script.primaryImage}
                refetch={refetch}
                isAuthor={isAuthor}
              />
            </Grid>
            <Grid item xs={12} sm={script.startLocation ? 6 : undefined}>
              <Typography paragraph>
                {description}
              </Typography>
              {script.tags.map((tag) => (
                <Chip
                  size="small"
                  label={config.script.tags.find((t) => t.id === tag).label}
                />
              ))}
            </Grid>
            {script.startLocation && (
              <Grid item xs={12} sm={6}>
                <GoogleMap
                  center={script.startLocation.coordinates}
                  zoom={10}
                  content={script.startLocation.description}
                  showCenterMarker
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {!loggedIn && <Alert severity="warning">You&apos;ll need to log in to play this game</Alert>}
        {loggedIn && (
          <ButtonLink variant="contained" color="primary" to={`${pathname}/${price.value && !isAuthor ? 'buy' : 'play'}`}>
            <PlayIcon />
            Play
          </ButtonLink>
        )}
        {(loggedIn && ((!price.value && !price.fixed) || isAuthor)) && (
          // Can only host your own paid script or free ones currently
          <Box component="span" ml={2}>
            <ButtonLink variant="contained" to={`${pathname}/host`}>
              <PersonIcon />
              Host
            </ButtonLink>
          </Box>
        )}
        {isAuthor && (
          <>
            <Box component="span" ml={2}>
              <ButtonLink variant="contained" to={`${pathname}/edit`}>
                <EditIcon />
                Edit
              </ButtonLink>
            </Box>
            <Box component="span" ml={2}>
              <Button variant="contained" onClick={() => setDeleteDialogOpen(true)}>
                <DeleteIcon />
                Delete
              </Button>
            </Box>
          </>
        )}
      </Paper>
      <AlertDialog
        title="Delete script"
        content="Are you sure you want to delete this script?"
        open={deleteDialogIsOpen}
        handleClose={() => setDeleteDialogOpen(false)}
      >
        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
          No
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          Yes
        </Button>
      </AlertDialog>
    </Layout>
  );
};

export default ScriptDetail;
