import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
});

// TODO: Fix for SSR
// const makeStore = () => (window && window.devToolsExtension
//   ? window.devToolsExtension()(createStore)
//   : createStore)(reducer);

const makeStore = () => createStore(reducer);

export default makeStore;
