import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Layout from '../components/layout';
import SEO from '../components/seo';
import URLQueryHandler from '../components/URLQueryHandler';

const what = `The idea is that anyone can create a series of questions, each with any number of answers and hints. This might be a clue trail, treasure hunt or a quiz. Anything that follows a question and answer format that can be played via SMS.

The script can then be played by entering a phone number, they'll receive the questions and respond via text.
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <URLQueryHandler />
      <Box m={4}>
        <Typography align="center" variant="h4">Scriptic</Typography>
        <Typography align="center">An SMS Adventure Platform (under construction)</Typography>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="h6">What?</Typography>
            <Typography variant="p">{what}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="h6">Why?</Typography>
            <Typography variant="p">{`Inspired by the many text based clue trail/adventure offerings in various cities but providing more of an open platform for creators to share script ideas and make the technology accessible to everyone. It's free to join and start creating scripts for people to play, you'll only pay for text messages sent and received in games you play or host. You'll get 20 free credits to test it out`}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="h6">How much?</Typography>
            <Typography variant="p">{`It costs the host one credit per automated message sent or received plus any fee set by the script author. Free scripts that are for brand promotions etc should be marked as sponsored. Script creators get the fee set (minus 10%) every time somebody plays (or hosts) their script.`}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="h6">Ready?</Typography>
            <Typography variant="p">{`If you came here because you received a text message from us then the best thing is to try to finish the script by answering the questions correctly. On completion you'll get a magic link which gives you 40 free credits for signing up. Otherwise you can just sign up to get started or browse the list of public scripts to see what's on offer`}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default IndexPage;
