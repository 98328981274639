import React from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import round from 'lodash/round';
import mean from 'lodash/mean';

import StarIcon from '@material-ui/icons/Star';
import PlayIcon from '@material-ui/icons/PlayArrow';
import TrailIcon from '@material-ui/icons/NearMe';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import config from '../../config';
import {
  Paper,
  Chip,
} from '../../components/UI';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ScriptCard from './ScriptCard';
import { MasonryGrid } from './user.style';

const UserDetail = ({ user, refetch }) => {
  const { name, scripts: { data: scripts } } = user;

  const { tags, plays } = scripts.reduce(
    (acc, script) => ({
      images: [...acc.images, ...get(script, ['images', 'data'], [])],
      tags: uniq([...acc.tags, ...script.tags]),
      plays: acc.plays + script.stats.completed,
      reviews: acc.reviews + script.stats.reviews,
    }),
    {
      images: [],
      tags: [],
      plays: 0,
      reviews: 0,
    },
  );

  const rating = mean(scripts
    .filter((script) => script.stats.rating !== null)
    .map((script) => script.stats.rating));

  return (
    <Layout>
      <SEO title={`${name} | Users | Scriptic`} />
      <Paper>
        <Box mb={1}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">{name}</Typography>
            </Grid>
            <Grid item>
              <Chip
                size="small"
                icon={<TrailIcon />}
                variant="outlined"
                label={`${scripts.length} Trails`}
              />
              <Chip
                size="small"
                icon={<PlayIcon />}
                variant="outlined"
                label={`${plays} Plays`}
              />
              {!!rating && (
                <Chip
                  size="small"
                  icon={<StarIcon />}
                  variant="outlined"
                  label={round(rating, 1)}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box pt={2} pb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {tags.map((tag) => (
                <Chip
                  size="small"
                  label={config.script.tagsById[tag].label}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <MasonryGrid>
                {scripts.map((script) => (
                  <div>
                    <ScriptCard script={script} />
                  </div>
                ))}
              </MasonryGrid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
};

export default UserDetail;
